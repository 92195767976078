<template>
  <div class="rchip">
    <v-chip
      ref="rchip--vchip"
      class="rchip--chip"
      :ripple="false"
      :removable="removable"
      :outlined="outlined"
      :textonly="textonly"
      :class="{
        circle,
        light,
        disabled,
        'v-chip--active': active,
        hasIcon: icon !== '',
      }"
      :style="widthStyle"
      @click="onClick"
    >
      <div
        v-if="width"
        class="text-truncate"
        :title="title || label"
        :style="widthStyle"
      >
        {{ label }}
      </div>
      <span
        v-else
        class="text-truncate"
        :title="title || label"
      >
        {{ label }}
      </span>
      <v-icon
        v-if="icon"
        small
      >
        {{ icon }}
      </v-icon>
      <v-icon
        v-if="removable===true && textonly===false"
        data-testid="icon"
        small
        class="rchip--remove-icon"
      >
        close
      </v-icon>
    </v-chip>
  </div>
</template>

<script>
export default {
  name: 'RChip',
  props: {
    label: {
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      default: false,
    },
    circle: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
    removable: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    textonly: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    width: [Number, String],
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    widthStyle() {
      const { width } = this.$props
      if (!width) {
        return { width: 'auto' }
      }
      if (Number.isFinite(width)) {
        return { width: `${width}px` }
      }
      return { width }
    },
  },
  methods: {
    onClick() {
      if (!this.$props.disabled) {
        this.$emit('click')
      }
    },
  },
}
</script>

<style scoped>
.rchip--chip {
  position: relative;
}
.rchip--remove-icon {
  position: absolute;
  top: -8px;
  right: -7px;
}
/deep/ .theme--light.v-chip {
  font-size: 0.92rem;
  overflow: visible;
  color: var(--r-text-color);
}
/deep/ .theme--light.v-chip.light:not(.v-chip--active) {
  background-color: var(--r-light-grey);
}
/deep/ .theme--light.v-chip--active,
/deep/ .theme--light.v-chip:not(.v-chip--active):hover {
  background-color: var(--primary-color);
  color: rgb(255,255,255);
}

/deep/ .theme--light.v-chip:not(.v-chip--active).v-chip--outlined {
  background-color: transparent;
  border-color: var(--light-grey-color);
}

/deep/ .theme--light.v-chip--outlined.v-chip--active,
/deep/ .theme--light.v-chip:not(.v-chip--active).v-chip--outlined:hover {
  background-color: transparent;
  border-color: var(--primary-color);
  color: var(--primary-color);
}

/deep/ .theme--light.v-chip:not(.v-chip--active):not(.v-chip--outlined)[textonly=true] {
  background-color: transparent;
  border: none;
}

/deep/ .theme--light.v-chip--active[textonly=true],
/deep/ .theme--light.v-chip:not(.v-chip--active):not(.v-chip--outlined)[textonly=true]:hover {
  background-color: var(--light-grey-color);
  color: var(--primary-color);
}

/deep/ .theme--light.v-chip.circle {
  border-radius: 20px;
  padding: 0 6px;
}
/deep/ .theme--light.v-chip.hasIcon {
  padding: 0 2px;
}
/deep/ .theme--light.v-chip:not(.v-chip--active).disabled,
/deep/ .theme--light.v-chip:not(.v-chip--active).disabled:hover {
  cursor: default;
  background-color: var(--light-grey-color) !important;
  border-color: var(--light-grey-color);
  color: var(--r-text-color);
}
</style>
